import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const MetaTags = ({ title, description, thumbnail }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{title || t("default.helmet.title")}</title>
        {description && <meta name="description" content={description} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        <meta property="og:site_name" content="SafeTag Tracking" />
        <meta
          property="og:title"
          content={title || t("default.helmet.title")}
        />
        <meta property="og:type" content="website" />
        {thumbnail && (
          <meta
            property="og:image"
            content={`https://www.safetagtracking.com/assets/thumbnail/${thumbnail}.png`}
          />
        )}
        <meta
          property="og:url"
          content={`https://www.safetagtracking.com${location.pathname}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </>
  );
};

export default MetaTags;
