import React from "react";
import { IoPersonAddOutline } from "react-icons/io5";
import AddMember from "./AddMember";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteMember from "./DeleteMember";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  handleAddMultiUser,
  handleDeleteMultiUser,
  handleFetchMultiUsers,
  handleUpdateMultiUser,
} from "../../../../utils/api";
import { toast } from "sonner";

const Sharing = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const qc = useQueryClient();

  const multiusers = useQuery({
    queryKey: ["multiusers"],
    queryFn: handleFetchMultiUsers,
    onError: (e) => {
      console.log(e);
    },
  });

  const addMember = useMutation({
    mutationFn: handleAddMultiUser,
    onSuccess: (data) => {
      if (data.message === "Error: User already invited.") {
        toast.error(data.user + " " + t("account.users.add_error1"));
      } else if (
        data.message === "Error: User already exists as a standard user."
      ) {
        toast.error(t("account.users.add_error2"));
      } else if (data.success === false) {
        toast.error(t("account.users.add_error"));
      } else {
        setShowModal(false);
        toast.success(t("account.users.add_success") + " " + data.user);
      }
      qc.invalidateQueries("multiusers");
    },
    onError: (e) => {
      console.log(e);
      toast.error(t("account.users.add_error"));
    },
  });

  const deleteMember = useMutation({
    mutationFn: handleDeleteMultiUser,
    onSuccess: (data) => {
      qc.invalidateQueries("multiusers");
      setSelected(null);
      toast.success(t("account.users.delete_success") + " " + data.user);
    },
    onError: (e) => {
      toast.error(t("account.users.delete_error"));
    },
  });

  const updateMember = useMutation({
    mutationFn: handleUpdateMultiUser,
    onSuccess: (data) => {
      console.log(data);
      qc.invalidateQueries("multiusers");
      toast.success(t("account.users.update_success") + " " + data.user);
    },
    onError: (e) => {
      toast.error(t("account.users.update_error"));
    },
  });

  const onUpdate = (e) => {
    updateMember.mutate({
      id: e.target.name,
      role: e.target.value,
    });
  };

  return (
    <div className={`w-full sm:px-4`}>
      <AddMember
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        mutation={addMember}
      />

      <DeleteMember
        isOpen={selected !== null}
        onClose={() => setSelected(null)}
        mutation={deleteMember}
        member={multiusers?.data?.find((a) => a._id === selected)}
      />
      <div
        className={`w-full ${
          multiusers?.data?.length === 0
            ? "p-4 bg-neutral-50 sm:bg-transparent"
            : "p-0"
        } sm:border sm:rounded-lg flex flex-col mt-6 gap-6 sm:p-8`}
      >
        <div className="flex flex-col bg-neutral-50 p-4 sm:p-0 sm:bg-transparent sm:flex-row gap-4 sm:items-center justify-between">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-semibold">
              {t("account.users.title")}
            </h2>
            <p>{t("account.users.subheading")}</p>
          </div>
          {multiusers?.data?.length > 0 && (
            <button
              onClick={() => setShowModal(true)}
              className="rounded-lg py-3 px-6 bg-pr hover:bg-sr text-white transition-all duration-300 flex flex-row items-center justify-center gap-2"
            >
              <IoPersonAddOutline className="text-xl -scale-x-100" />
              {t("account.users.add")}
            </button>
          )}
        </div>
        {multiusers?.data?.length === 0 && (
          <div className="flex flex-col sm:items-center gap-4 p-4 py-8 bg-neutral-100 border rounded-xl">
            <p className="text-center w-full max-w-sm opacity-70">
              {t("account.users.no_members")}
            </p>
            <button
              onClick={() => setShowModal(true)}
              className="rounded-lg py-3 px-6 bg-pr hover:bg-sr text-white transition-all duration-300 flex flex-row items-center justify-center gap-2"
            >
              <IoPersonAddOutline className="text-xl -scale-x-100" />
              {t("account.users.add")}
            </button>
          </div>
        )}

        {multiusers?.data?.length > 0 && (
          <div>
            <p className="font-semibold text-black text-opacity-70 px-4 sm:px-0">
              {t("account.users.access")}
            </p>
            <div className="flex flex-col sm:gap-4 mt-3 bg-neutral-50 sm:bg-transparent">
              {multiusers?.data.map((member, index) => (
                <div
                  className="flex p-4 flex-row gap-1 sm:gap-2 w-full sm:p-5  items-center border-b sm:border sm:rounded-lg"
                  key={index}
                >
                  <div className="border shrink-0 flex h-8 sm:h-10 w-8 sm:w-10 rounded-full overflow-hidden">
                    <img
                      className="h-full w-full"
                      src={`https://ui-avatars.com/api/?background=fafafa&color=000&name=${
                        member.name ? member.name : member._id
                      }&length=${member.name?.split(" ").length > 1 ? 2 : 1}`}
                    />
                  </div>
                  <div className="flex min-w-0 overflow-hidden flex-col sm:flex-row gap-1 sm:gap-2 items-start sm:items-center flex-1">
                    <div className="flex flex-col text-sm overflow-x-auto">
                      <p className="font-medium">{member.name || member._id}</p>
                      {member?.name ? (
                        <p className="text-neutral-500">{member._id}</p>
                      ) : null}
                    </div>
                    {member?.cognito_id === null && (
                      <div className="border text-xs bg-neutral-50 rounded px-2 py-1 sm:px-3 sm:py-2 text-neutral-600">
                        {t("account.users.invite_pending")}
                      </div>
                    )}
                  </div>
                  <div className="flex shrink-0 items-center gap-2">
                    <select
                      className="appearance-none cursor-pointer px-7 py-1 text-sm bg-transparent border-none outline-none focus:outline-none text-gray-900"
                      name={member._id}
                      value={member.role}
                      onChange={onUpdate}
                    >
                      <option value="viewer">
                        {t("account.users.viewer")}
                      </option>
                      <option value="editor">
                        {t("account.users.editor")}
                      </option>
                    </select>
                    <RiDeleteBin6Line
                      className="text-xl cursor-pointer hover:opacity-50 transition-all duration-300"
                      onClick={() => setSelected(member._id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sharing;
